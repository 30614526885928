import { useGlobalStore } from "@two-ui/global-store";
import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import { ref } from "vue";

export class PlaygroundModeError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

// Create a ref to control modal visibility
export const showPlaygroundModal = ref(false);

type PlaygroundModeGuard =
  | boolean
  | ((route: RouteLocationNormalized) => boolean);

/**
 * Restricts access to routes based on playground mode and route meta
 * @param to
 * @param from
 * @returns route or undefined
 */
export default function usePlaygroundRestrictionGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
): RouteLocationRaw | undefined {
  const globalStore = useGlobalStore();

  // If playground mode is enabled, only allow home route, debug routes, admin routes and routes marked as allowed
  if (globalStore.playgroundMode) {
    const allowInPlayground = to.meta
      .allowInPlaygroundMode as PlaygroundModeGuard;
    if (
      to.name === "home" ||
      to.path.startsWith("/debug") ||
      to.path.startsWith("/admin") ||
      (typeof allowInPlayground === "function"
        ? allowInPlayground(to)
        : allowInPlayground)
    ) {
      return undefined;
    }
    // Show the modal for blocked routes
    showPlaygroundModal.value = true;
    return from.name ? { name: from.name } : { name: "home" };
  }

  // If playground mode is disabled, allow all routes
  return undefined;
}
