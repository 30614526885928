<template>
  <Modal
    title="Your Privacy Matters to Us 🔒"
    :show="
      store.getDataProcessingTncAccepted() === null &&
      store.state.featureToggles.enableConsentBanner
    "
    :doNotClose="true"
    containerClass="w-[626px] pb-0 consent-banner"
  >
    <template #body>
      <img
        src="@workspace/assets/images/consent-banner.png"
        alt="Consent Banner"
        width="578"
      />
      <div class="max-h-[300px] overflow-scroll text-sm">
        <p class="mb-2 mt-4 text-grey-600">
          Runa is committed to ensuring the privacy and security of your data.
          We want to be transparent about how we collect and use your
          information to provide you with the best possible experience. By using
          our software, you agree to our data collection practices outlined
          below:
        </p>
        <ol class="mb-2 ml-2">
          <li>
            <strong class="font-bold">1. Improving Our Software:</strong> We
            collect data to continuously enhance and optimize our software's
            performance. Your feedback and usage patterns help us identify areas
            for improvement and deliver a seamless user experience.
          </li>
          <li>
            <strong class="font-bold"
              >2. Personalized Marketing Content:</strong
            >
            With your consent, we may occasionally send you relevant marketing
            content to enhance your experience with our products and services.
            This includes updates, promotions, and special offers tailored to
            your preferences.
          </li>
          <li>
            <strong class="font-bold">3. Third-Party Collaboration:</strong> We
            collaborate with trusted third-party partners to enable certain
            features and improvements in our software. While we may share
            limited information with these partners, rest assured that we never
            sell or compromise your data for monetary gain. Your privacy remains
            our top priority.
          </li>
        </ol>
        <p class="mb-2">
          Your trust is paramount to us. If you're comfortable with these terms,
          please press "Accept" below to continue enjoying our services.
        </p>
        <p class="mb-2">
          Remember, your service will still work even if you choose to decline.
          Your satisfaction and privacy are our utmost priorities.
        </p>
        <p class="mb-2">
          Thank you for entrusting us with your data. We are dedicated to
          providing you with a secure and enjoyable experience.
        </p>
        <p class="mt-4 text-sm text-grey-600">
          For further information visit Runa's
          <a
            target="_blank"
            href="https://runa.io/privacy"
            class="text-[#505BF0]"
          >
            Privacy Policy.
          </a>
        </p>
        <div class="my-4 flex justify-end">
          <TwoButton @click="handleUserAction(false)"> Decline </TwoButton>
          <TwoButton
            :loading="loading"
            class="btn-primary"
            @click="handleUserAction(true)"
          >
            Accept
          </TwoButton>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="bg-fade mt-[-35px] h-[35px] w-full"></div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Modal from "@two-ui/components/common/Modal.vue";
import TwoButton from "@two-components/components/TwoButton.vue";
import { UserService } from "@workspace/open-api/cms";
import { captureException } from "@two-ui/sentry";
import { SUPPORT_EMAIL } from "@two-ui/constants";
import store from "@two-ui/store";

const loading = ref(false);

const handleUserAction = async (accepted: boolean) => {
  try {
    if (accepted) {
      loading.value = true;
      await UserService.updateUserMeOptions({
        dataProcessingTncAccepted: accepted,
      });
    }
  } catch (ex) {
    alert(
      `There was a problem in processing the terms and conditions. Please try again or contact ${SUPPORT_EMAIL} if the problem persists.`
    );
    captureException(ex);
    throw ex;
  } finally {
    store.setDataProcessingTncAccepted(false);
    loading.value = false;
  }
};
</script>

<style scoped lang="sass">
.bg-fade
  background: linear-gradient(#fff0,20%,#fff,90%,#fff)
</style>
