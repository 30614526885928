import { defineStore } from "pinia";
import { getJwtToken } from "@two-ui/auth0";
import store from "./store";
import { FeatureFlaggedCurrencies } from "@two-ui/constants";
import { RegionEnum } from "./types";

export const useGlobalStore = defineStore("globalStore", {
  state: () => ({
    customerDefaultCurrency: "$",
    token: getJwtToken,
    customerName: null as string | null,
    customerId: null as string | null,
    useBalancesTncAccepted: null as boolean | null,
    customerContactEmail: null as string | null,
    brandApprovalAccessToken: null as string | null,
    customerFeatureFlags: null as {
      floats: boolean;
      cardFees: boolean;
      fx: boolean;
      breakage: boolean;
      discounts: boolean;
    } | null,
    customerSource: null as string | null,
    userPermissions: null as Array<string> | null,
    currencyToggles: null as Record<FeatureFlaggedCurrencies, boolean> | null,
    enableTransactionCsvDownload: null as boolean | null,
    shouldPreselectBannerOrderData: false as boolean,
    displayPromotionalBanner: false as boolean,
    region: RegionEnum.Default,
    enableTopUpInvoices: null as boolean | null,
    enableLowBalanceBadges: null as boolean | null,
    timezoneOverride: null as string | null,
    promoBannerClicked: false as boolean,
    playgroundMode: false as boolean,
  }),
  actions: {
    clearTimezoneOverride() {
      this.timezoneOverride = null;
    },

    setTimezoneOverride(timezone: string) {
      this.timezoneOverride = timezone;
    },

    openLink(tagIdentifier: string) {
      if (store.shouldShowNewTag(tagIdentifier)) {
        store.hideNewTag(tagIdentifier);
      }
    },

    setDisplayPromotionalBanner(dislayBanner: boolean) {
      this.displayPromotionalBanner = dislayBanner;
    },
    setGeolocation(region: RegionEnum) {
      this.region = region;
    },
  },
});
