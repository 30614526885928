import { DirectiveBinding } from "vue";
import { useGlobalStore } from "@two-ui/global-store";

const hideInPlaygroundDirective = {
  mounted(el: HTMLElement) {
    const globalStore = useGlobalStore();

    if (globalStore.playgroundMode) {
      el.style.display = "none";
    }
  },
};

export default hideInPlaygroundDirective;
