import { defineAsyncComponent } from "vue";
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteLocationRaw,
} from "vue-router";
import LoadingSpinner from "@two-ui/components/LoadingSpinner.vue";
import AuthCallback from "@two-ui/views/AuthCallback.vue";
import AuthLogin from "@two-ui/views/AuthLogin.vue";
import AuthLogout from "@two-ui/views/AuthLogout.vue";
import AuthSignup from "@two-ui/views/AuthSignup.vue";
import NotFound from "@two-ui/views/NotFound.vue";
import InviteLink from "@two-ui/views/InviteLink.vue";
import OnboardingLink from "@two-ui/views/OnboardingLink.vue";
import UserSettings from "@two-ui/views/UserSettings.vue";
import { GuardAction, UserOnboardingState, UserPermissions } from "./types";
import { userLoggedInWithPublicEmail } from "./auth0";
import customerIdRestrictionGuard from "@two-ui/route-guards/customer-id-restriction";
import useBalancesRestrictionGuard from "@two-ui/route-guards/use-balances-restriction";
import toggleFeatureRestrictionGuard from "@two-ui/route-guards/feature-toggle-restriction";
import balanceTncAcceptedRestrictionGuard from "@two-ui/route-guards/balance-tnc-accepted-restriction";
import auth0PermissionsRestrictionGuard, {
  Auth0NoPermissionsNavigationError,
} from "./route-guards/auth0-role-restriction";
import { captureException } from "./sentry";
import {
  PAYOUT_TEMPLATE_HELP_URL,
  REDEMPTION_TEMPLATE_HELP_URL,
} from "@customization/constants";
import useFXRestrictionGuard, {
  FXRestrictionError,
} from "./route-guards/use-fx-restriction";
import usePlaygroundRestrictionGuard, {
  PlaygroundModeError,
} from "@two-ui/route-guards/use-playground-restriction";
import ApiKeysAuthCallback from "@two-ui/views/ApiKeysAuthCallback.vue";

const onboardingBundle = async () => import("@two-ui/bundles/onboarding.js");
const orderingBundle = async () => import("@two-ui/bundles/ordering.js");
const teamBundle = async () => import("@two-ui/bundles/team.js");
const paymentMethodsBundle = async () =>
  import("@two-ui/bundles/payment-methods.js");
const apiKeyBundle = async () => import("@two-ui/bundles/api-keys.js");
const debugBundle = async () => import("@two-ui/bundles/debug.js");
const shopBundle = async () => import("@two-ui/bundles/shop.js");

const orderJourneyOverlayMetaOptions = {
  overlay: true,
  // do not persist scroll to avoid conflicting scrolling with close animation
  // causing messy overlay close animation
  scrollTopOnLeave: true,
};
export const routes = [
  // Non lazy loaded routes
  {
    path: "/",
    name: "home",
    component: async () => (await orderingBundle()).Home,
  },
  {
    path: "/login",
    component: AuthLogin,
    name: "auth-login",
    meta: { preAuth: true },
  },
  {
    path: "/logout",
    component: AuthLogout,
    name: "auth-logout",
    meta: { preAuth: true, allowInPlaygroundMode: true },
  },
  {
    path: "/signup",
    component: AuthSignup,
    name: "auth-signup",
    meta: { preAuth: true, noIndex: true },
  },
  {
    path: "/auth/callback",
    component: AuthCallback,
    name: "auth-callback",
    meta: { preAuth: true, hideSidebar: true, allowInPlaygroundMode: true },
  },
  {
    path: "/api-keys/auth/callback",
    component: ApiKeysAuthCallback,
    name: "api-keys-auth-callback",
    meta: { preAuth: true, hideSidebar: true },
  },
  {
    path: "/invite-link",
    component: InviteLink,
    name: "invite-link",
    meta: { preAuth: true },
  },
  {
    path: "/onboarding-link",
    component: OnboardingLink,
    name: "onboarding-link",
    meta: { preAuth: true, allowInPlaygroundMode: true },
  },
  // Lazy loaded routes
  {
    path: "/onboard",
    name: "onboard",
    redirect: "/onboard/account-details",
    component: async () => (await onboardingBundle()).OnboardingRoot,
    meta: { hideSidebar: true },
    children: [
      {
        path: "closed",
        name: "onboarding-closed",
        component: async () => (await onboardingBundle()).OnboardingClosed,
        meta: { preAuth: true },
      },
      {
        path: "verify-email",
        name: "onboarding-verify-email",
        component: async () => (await onboardingBundle()).VerifyEmail,
        meta: { preAuth: true },
      },
      {
        path: "account-details",
        name: "onboarding-account-details",
        component: async () => (await onboardingBundle()).AccountDetails,
      },
      {
        path: "company-details",
        name: "onboarding-company-details",
        component: async () => (await onboardingBundle()).CompanyDetails,
      },
      {
        path: "user-segmentation",
        name: "onboarding-user-segmentation",
        component: async () => (await onboardingBundle()).UserSegmentation,
      },
      {
        path: "customer-exists",
        name: "onboarding-customer-exists",
        component: async () => (await onboardingBundle()).CustomerExists,
      },
      {
        path: "pending-confirmation",
        name: "onboarding-pending-confirmation",
        component: async () => (await onboardingBundle()).PendingConfirmation,
      },
      {
        path: "taking-long",
        name: "onboarding-taking-long",
        component: async () => (await onboardingBundle()).OnboardingTakingLong,
      },
      {
        path: "personal-email-restriction",
        name: "onboarding-personal-email-restriction",
        component: async () =>
          (await onboardingBundle()).PersonalEmailRestriction,
      },
      {
        path: "invite-expired",
        name: "onboarding-invite-expired",
        component: async () =>
          (await onboardingBundle()).OnboardingInviteExpired,
      },
    ],
  },
  {
    path: "/order",
    name: "order",
    redirect: "/order/create",
    component: async () => (await orderingBundle()).QuickOrderJourneyRoot,
    meta: {
      hideSidebar: true,
      requiresCustomerId: true,
      permissionGuards: {
        permissions: [
          UserPermissions.WRITE_ORDERS,
          UserPermissions.READ_PRODUCTS,
        ],
        guardAction: GuardAction.AND,
      },
    },
    children: [
      {
        path: "create",
        name: "order-create",
        component: async () => (await orderingBundle()).OrderCreate,
        meta: {
          permissionGuards: {
            permissions: [
              UserPermissions.WRITE_ORDERS,
              UserPermissions.READ_PRODUCTS,
            ],
            guardAction: GuardAction.AND,
          },
        },
      },
      {
        path: "brands",
        name: "order-brands",
        component: async () => (await orderingBundle()).OrderBrands,
        meta: {
          ...orderJourneyOverlayMetaOptions,
          returnToRouteName: "order-create",
          permissionGuards: {
            permissions: [UserPermissions.WRITE_ORDERS],
            guardAction: GuardAction.AND,
          },
        },
      },
      {
        path: "message",
        name: "order-message",
        component: async () => (await orderingBundle()).OrderMessage,
        meta: {
          ...orderJourneyOverlayMetaOptions,
          returnToRouteName: "order-create",
          permissionGuards: {
            permissions: [UserPermissions.WRITE_ORDERS],
            guardAction: GuardAction.AND,
          },
        },
      },
      {
        path: "quantity",
        name: "order-quantity",
        component: async () => (await orderingBundle()).OrderQuantity,
        meta: {
          scrollTopOnEnter: true,
          permissionGuards: {
            permissions: [UserPermissions.WRITE_ORDERS],
            guardAction: GuardAction.AND,
          },
        },
      },
      {
        path: "payment",
        name: "order-payment",
        component: async () => (await orderingBundle()).OrderPayment,
        meta: {
          scrollTopOnEnter: true,
          permissionGuards: {
            permissions: [UserPermissions.WRITE_ORDERS],
            guardAction: GuardAction.AND,
          },
        },
      },
      {
        path: "recipients",
        name: "order-email-recipients",
        component: async () => (await orderingBundle()).OrderEmailRecipients,
        meta: {
          ...orderJourneyOverlayMetaOptions,
          permissionGuards: {
            permissions: [UserPermissions.WRITE_ORDERS],
            guardAction: GuardAction.AND,
          },
        },
      },
    ],
  },
  {
    path: "/order/type",
    name: "order-type-selection",
    component: async () => (await orderingBundle()).OrderTypeSelection,
    meta: {
      hideSidebar: true,
      permissionGuards: {
        permissions: [UserPermissions.WRITE_ORDERS],
        guardAction: GuardAction.AND,
      },
    },
  },
  {
    path: "/bulk",
    name: "bulk-order",
    redirect: "/bulk/create",
    component: async () => (await orderingBundle()).BulkOrderJourneyRoot,
    meta: {
      hideSidebar: true,
      requiresCustomerId: true,
      permissionGuards: {
        permissions: [UserPermissions.WRITE_ORDERS],
        guardAction: GuardAction.AND,
      },
    },
    children: [
      {
        path: "create",
        name: "bulk-order-create",
        component: async () => (await orderingBundle()).OrderCreateBulk,
        meta: {
          permissionGuards: {
            permissions: [UserPermissions.WRITE_ORDERS],
            guardAction: GuardAction.AND,
          },
        },
      },
      {
        path: "brands",
        name: "bulk-order-brands",
        component: async () => (await orderingBundle()).OrderBrandsBulk,
        meta: {
          ...orderJourneyOverlayMetaOptions,
          returnToRouteName: "bulk-order-create",
          permissionGuards: {
            permissions: [UserPermissions.WRITE_ORDERS],
            guardAction: GuardAction.AND,
          },
        },
      },
      {
        path: "message",
        name: "bulk-order-message",
        component: async () => (await orderingBundle()).OrderMessage,
        meta: {
          ...orderJourneyOverlayMetaOptions,
          returnToRouteName: "bulk-order-create",
          permissionGuards: {
            permissions: [UserPermissions.WRITE_ORDERS],
            guardAction: GuardAction.AND,
          },
        },
      },
      {
        path: "preview",
        name: "bulk-order-summary-preview",
        component: async () => (await orderingBundle()).PreviewBulkOrder,
        meta: {
          ...orderJourneyOverlayMetaOptions,
          returnToRouteName: "bulk-order-summary-payment",
          permissionGuards: {
            permissions: [UserPermissions.WRITE_ORDERS],
            guardAction: GuardAction.AND,
          },
        },
      },
      {
        path: "preview",
        name: "bulk-order-errors-preview",
        component: async () => (await orderingBundle()).PreviewBulkOrder,
        meta: {
          ...orderJourneyOverlayMetaOptions,
          returnToRouteName: "bulk-order-create",
          permissionGuards: {
            permissions: [UserPermissions.WRITE_ORDERS],
            guardAction: GuardAction.AND,
          },
        },
      },
      {
        path: "summary-payment",
        name: "bulk-order-summary-payment",
        component: async () => (await orderingBundle()).OrderSummaryPaymentBulk,
        meta: {
          scrollTopOnEnter: true,
          permissionGuards: {
            permissions: [UserPermissions.WRITE_ORDERS],
            guardAction: GuardAction.AND,
          },
        },
      },
    ],
  },

  {
    path: "/orders",
    name: "orders",
    component: async () => (await orderingBundle()).Orders,
    meta: {
      requiresCustomerId: true,
      breadCrumbTitle: "Order History",
      permissionGuards: {
        permissions: [UserPermissions.READ_ORDERS],
        guardAction: GuardAction.AND,
      },
    },
  },
  {
    path: "/orders/:id",
    name: "order-details",
    props: true,
    component: async () => (await orderingBundle()).OrderDetail,
    meta: {
      requiresCustomerId: true,
      breadCrumbTitle: " ",
      backHeadingButton: {
        label: " ",
        backRouteName: "orders",
        testId: "order-detail-back",
      },
      permissionGuards: {
        permissions: [UserPermissions.READ_ORDERS],
        guardAction: GuardAction.AND,
      },
    },
  },
  {
    path: "/orders-old",
    name: "order-list-old",
    component: async () => (await orderingBundle()).OrderList,
    meta: {
      requiresCustomerId: true,
      permissionGuards: {
        permissions: [UserPermissions.READ_ORDERS],
        guardAction: GuardAction.AND,
      },
    },
  },
  {
    path: "/orders-old/:id",
    name: "order-detail-old",
    props: true,
    component: async () => (await orderingBundle()).OrderDetailOld,
    meta: {
      requiresCustomerId: true,
      permissionGuards: {
        permissions: [UserPermissions.READ_ORDERS],
        guardAction: GuardAction.AND,
      },
    },
  },
  {
    path: "/team",
    name: "team-members",
    component: async () => (await teamBundle()).TeamMembers,
    meta: {
      requiresCustomerId: true,
      permissionGuards: {
        permissions: [UserPermissions.READ_TEAM_MEMBERS],
        guardAction: GuardAction.AND,
      },
    },
  },
  {
    path: "/team/invite",
    name: "invite-team-member",
    component: async () => (await teamBundle()).InviteTeamMember,
    meta: {
      hideSidebar: true,
      requiresCustomerId: true,
      permissionGuards: {
        permissions: [UserPermissions.WRITE_TEAM_MEMBERS],
        guardAction: GuardAction.AND,
      },
    },
  },
  {
    path: "/team/invite-sent",
    name: "team-member-invite-sent",
    component: async () => (await teamBundle()).TeamMemberInviteSent,
    meta: {
      hideSidebar: true,
      permissionGuards: {
        permissions: [UserPermissions.WRITE_TEAM_MEMBERS],
        guardAction: GuardAction.AND,
      },
    },
  },
  {
    path: "/stored-cards",
    name: "stored-cards",
    component: async () => (await paymentMethodsBundle()).PaymentMethods,
    meta: {
      requiresCustomerId: true,
      permissionGuards: {
        permissions: [UserPermissions.READ_PAYMENT_CARDS],
        guardAction: GuardAction.AND,
      },
    },
  },
  {
    path: "/payment-methods",
    redirect: { name: "stored-cards" },
  },
  {
    path: "/stored-cards/add",
    name: "add-stored-card",
    component: async () => (await paymentMethodsBundle()).AddPaymentMethod,
    meta: {
      hideSidebar: true,
      requiresCustomerId: true,
      permissionGuards: {
        permissions: [UserPermissions.WRITE_PAYMENT_CARDS],
        guardAction: GuardAction.AND,
      },
    },
  },
  {
    path: "/payment-methods/add",
    redirect: { name: "add-stored-card" },
  },
  {
    path: "/stored-cards/saved",
    name: "stored-card-saved",
    component: async () => (await paymentMethodsBundle()).PaymentMethodSaved,
    meta: { hideSidebar: true, scrollTopOnEnter: true },
  },
  {
    path: "/api-keys/create/:type",
    name: "api-keys-create",
    component: async () => (await apiKeyBundle()).ApiKeyCreate,
    meta: {
      hideSidebar: true,
      permissionGuards: {
        permissions: [UserPermissions.WRITE_API_KEYS],
        guardAction: GuardAction.AND,
      },
      allowInPlaygroundMode: (route: RouteLocationNormalized) =>
        route.params.type === "playground",
    },
  },
  {
    path: "/api-keys/created/:type",
    name: "api-keys-created",
    component: async () => (await apiKeyBundle()).ApiKeyCreated,
    meta: {
      requiresCustomerId: true,
      permissionGuards: {
        permissions: [UserPermissions.WRITE_API_KEYS],
        guardAction: GuardAction.AND,
      },
      allowInPlaygroundMode: (route: RouteLocationNormalized) =>
        route.params.type === "playground",
    },
  },
  {
    path: "/api-keys",
    name: "api-keys",
    component: async () => (await apiKeyBundle()).ApiKeyOverview,
    meta: {
      requiresCustomerId: true,
      permissionGuards: {
        permissions: [UserPermissions.READ_API_KEYS],
        guardAction: GuardAction.AND,
      },
      allowInPlaygroundMode: true,
    },
  },
  {
    path: "/admin/pending-customers",
    name: "pending-customers",
    component: async () => (await debugBundle()).PendingCustomers,
  },
  // Debug routes
  {
    path: "/debug",
    name: "debug",
    component: async () => (await debugBundle()).DebugIndex,
  },
  {
    path: "/debug/component",
    name: "debug-component-library",
    component: async () => (await debugBundle()).ComponentLibrary,
  },
  {
    path: "/debug/theme",
    name: "debug-theme",
    component: async () => (await debugBundle()).Theme,
  },
  {
    path: "/debug/features",
    name: "debug-feature-toggles",
    component: async () => (await debugBundle()).FeatureToggles,
  },
  {
    path: "/debug/full-page-loader",
    name: "debug-full-page-loader",
    component: async () => (await debugBundle()).DebugFullPageLoader,
  },
  {
    path: "/debug/order-complete",
    name: "debug-order-complete",
    component: async () => (await debugBundle()).DebugOrderCompleted,
  },
  {
    path: "/help",
    name: "help",
    component: async () => (await orderingBundle()).Help,
    meta: {
      allowInPlaygroundMode: true,
    },
  },
  {
    path: "/runa-network",
    name: "runa-network",
    component: async () =>
      defineAsyncComponent({
        loader: () => import("product_catalog_app/BasePage"),
        loadingComponent: LoadingSpinner,
      }),
    children: [
      {
        path: "",
        name: "product-catalog-home",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("product_catalog_app/Home"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          permissionGuards: {
            permissions: [UserPermissions.READ_PRODUCTS],
            guardAction: GuardAction.AND,
          },
          allowInPlaygroundMode: true,
        },
      },
      {
        path: "product-details/:productId",
        name: "product-details",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("product_catalog_app/ProductDetails"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          permissionGuards: {
            permissions: [UserPermissions.READ_PRODUCTS],
            guardAction: GuardAction.AND,
          },
          allowInPlaygroundMode: true,
        },
      },
    ],
    meta: {
      requiresCustomerId: true,
      permissionGuards: {
        permissions: [UserPermissions.READ_PRODUCTS],
        guardAction: GuardAction.AND,
      },
      allowInPlaygroundMode: true,
    },
  },
  {
    path: "/billing",
    name: "billing",
    component: async () =>
      defineAsyncComponent({
        loader: () => import("accounts_app/BasePage"),
        loadingComponent: LoadingSpinner,
      }),
    children: [
      {
        path: "",
        name: "billing-home",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("accounts_app/Home"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          breadCrumbTitle: "Balances",
        },
      },
      {
        path: "add-funds",
        name: "add-funds",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("accounts_app/AddFunds"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          requiresBalancesTncAccepted: true,
          breadCrumbTitle: "Add funds",
          backHeadingButton: {
            label: "Add funds",
            backRouteName: "billing-home",
            testId: "add-funds-back",
          },
          permissionGuards: {
            permissions: [
              UserPermissions.WRITE_TOP_UP_BY_BANK,
              UserPermissions.WRITE_TOP_UP_BY_CARD,
              UserPermissions.WRITE_TOP_UP_BY_FX,
            ],
            guardAction: GuardAction.OR,
          },
        },
        children: [
          {
            path: "bank-transfer",
            name: "bank-transfer",
            component: async () =>
              defineAsyncComponent({
                loader: () => import("accounts_app/BankTransfer"),
                loadingComponent: LoadingSpinner,
              }),
            meta: {
              requiresBalancesTncAccepted: true,
              breadCrumbTitle: "Bank transfer",
              backHeadingButton: {
                label: "Bank transfer",
                backRouteName: "add-funds",
                testId: "bank-transfer-back",
              },
              keepParams: true,
              permissionGuards: {
                permissions: [UserPermissions.WRITE_TOP_UP_BY_BANK],
                guardAction: GuardAction.AND,
              },
            },
            children: [
              {
                path: "bank-details",
                name: "bank-details",
                component: async () =>
                  defineAsyncComponent({
                    loader: () => import("accounts_app/BankDetails"),
                    loadingComponent: LoadingSpinner,
                  }),
                meta: {
                  requiresBalancesTncAccepted: true,
                  breadCrumbTitle: "Bank details",
                  backHeadingButton: {
                    label: "Bank details",
                    backRouteName: "bank-transfer",
                    testId: "bank-transfer-back",
                  },
                  permissionGuards: {
                    permissions: [UserPermissions.WRITE_TOP_UP_BY_BANK],
                    guardAction: GuardAction.AND,
                  },
                },
              },
            ],
          },
          {
            path: "card-top-up",
            name: "card-top-up",
            component: async () =>
              defineAsyncComponent({
                loader: () => import("accounts_app/CardTopUp"),
                loadingComponent: LoadingSpinner,
              }),
            meta: {
              requiresBalancesTncAccepted: true,
              breadCrumbTitle: "Card payment",
              backHeadingButton: {
                label: "Card payment",
                backRouteName: "add-funds",
                testId: "card-top-up-back-button",
              },
              permissionGuards: {
                permissions: [
                  UserPermissions.WRITE_TOP_UP_BY_CARD,
                  UserPermissions.READ_PAYMENT_CARDS,
                ],
                guardAction: GuardAction.AND,
              },
            },
            children: [
              {
                path: "card-success/:topup_id",
                name: "card-success",
                meta: {
                  requiresBalancesTncAccepted: true,
                  backHeadingButton: {
                    label: "",
                    backRouteName: "add-funds",
                    enabled: false,
                  },
                  permissionGuards: {
                    permissions: [UserPermissions.READ_BILLING],
                    guardAction: GuardAction.AND,
                  },
                },
                props: true,
                component: async () =>
                  defineAsyncComponent({
                    loader: () => import("accounts_app/TransferTopUpDetails"),
                    loadingComponent: LoadingSpinner,
                  }),
              },
            ],
          },
          {
            path: "currency-exchange",
            name: "currency-exchange",
            component: async () =>
              defineAsyncComponent({
                loader: () => import("accounts_app/CurrencyExchange"),
                loadingComponent: LoadingSpinner,
              }),
            meta: {
              requiresBalancesTncAccepted: false,
              breadCrumbTitle: "Runa FX",
              backHeadingButton: {
                label: "Runa FX",
                backRouteName: "add-funds",
                testId: "currency-exchange-back-button",
              },
              permissionGuards: {
                permissions: [UserPermissions.WRITE_TOP_UP_BY_FX],
                guardAction: GuardAction.AND,
              },
              requiresFXEnabledForCustomer: true,
            },
            children: [
              {
                path: "trade-success/:trade_id",
                name: "trade-success",
                meta: {
                  requiresBalancesTncAccepted: true,
                  backHeadingButton: {
                    label: "",
                    backRouteName: "add-funds",
                    enabled: false,
                  },
                  permissionGuards: {
                    permissions: [UserPermissions.READ_BILLING],
                    guardAction: GuardAction.AND,
                  },
                },
                component: async () =>
                  defineAsyncComponent({
                    loader: () =>
                      import("accounts_app/CurrencyExchangeReceipt"),
                    loadingComponent: LoadingSpinner,
                  }),
              },
            ],
          },
        ],
      },
      {
        path: "top-up/:topup_id",
        name: "top-up",
        props: true,
        component: async () =>
          defineAsyncComponent({
            loader: () => import("accounts_app/TransferTopUpDetails"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          permissionGuards: {
            permissions: [UserPermissions.READ_BILLING],
            guardAction: GuardAction.AND,
          },
        },
      },
      {
        path: "trade/:trade_id",
        name: "trade",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("accounts_app/CurrencyExchangeReceipt"),
            loadingComponent: LoadingSpinner,
          }),
      },
    ],
    meta: {
      requiresCustomerId: true,
      requiresBalancesEnabledForCustomer: true,
      permissionGuards: {
        permissions: [UserPermissions.READ_BILLING],
        guardAction: GuardAction.AND,
      },
    },
  },
  {
    path: "/runa-shop",
    name: "runa-shop",
    component: async () => (await shopBundle()).ShopForRuna,
    meta: {
      requiresCustomerId: true,
      breadCrumbTitle: "Runa Shop",
    },
  },
  {
    path: "/statements",
    name: "statements",
    component: async () =>
      defineAsyncComponent({
        loader: () => import("accounts_app/BasePage"),
        loadingComponent: LoadingSpinner,
      }),
    children: [
      {
        path: "",
        name: "statements-home",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("accounts_app/Statements"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          permissionGuards: {
            permissions: [UserPermissions.READ_STATEMENTS],
            guardAction: GuardAction.AND,
          },
        },
      },
    ],
    meta: {
      requiresCustomerId: true,
      link: "/billing/statements",
      permissionGuards: {
        permissions: [UserPermissions.READ_STATEMENTS],
        guardAction: GuardAction.AND,
      },
    },
  },
  {
    path: "/transactions",
    name: "transactions",
    component: async () =>
      defineAsyncComponent({
        loader: () => import("accounts_app/BasePage"),
        loadingComponent: LoadingSpinner,
      }),
    children: [
      {
        path: "",
        name: "transactions-home",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("accounts_app/Transactions"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          permissionGuards: {
            permissions: [UserPermissions.READ_ACTIVITY_HISTORY],
            guardAction: GuardAction.AND,
          },
        },
      },
    ],
    meta: {
      link: "/billing/transactions",
      requiresCustomerId: true,
      permissionGuards: {
        permissions: [UserPermissions.READ_ACTIVITY_HISTORY],
        guardAction: GuardAction.AND,
      },
    },
  },
  {
    path: "/product-approval",
    name: "product-approval",
    component: async () =>
      defineAsyncComponent({
        loader: () => import("bap_app/BasePage"),
      }),
    redirect: "/product-approval/home",
    children: [
      {
        path: "home",
        name: "product-approval-home",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("bap_app/ProductApproval"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          permissionGuards: {
            permissions: [UserPermissions.READ_PRODUCT_APPROVALS],
            guardAction: GuardAction.AND,
          },
        },
      },
      {
        path: "provide-information/:action/:caseReference",
        name: "provide-information",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("bap_app/ProvideInformation"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          permissionGuards: {
            permissions: [UserPermissions.READ_PRODUCT_APPROVALS],
            guardAction: GuardAction.AND,
          },
        },
      },
    ],
    meta: {
      requiresCustomerId: true,
      permissionGuards: {
        permissions: [UserPermissions.READ_PRODUCT_APPROVALS],
        guardAction: GuardAction.AND,
      },
    },
  },
  {
    path: "/forms",
    name: "forms",
    component: async () =>
      defineAsyncComponent({
        loader: () => import("baf_app/FormBase"),
        loadingComponent: LoadingSpinner,
      }),
    children: [
      {
        path: "t2",
        name: "t2-form",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("baf_app/T2Form"),
            loadingComponent: LoadingSpinner,
          }),
      },
      {
        path: "select-countries",
        name: "select-countries",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("baf_app/SelectCountries"),
            loadingComponent: LoadingSpinner,
          }),
      },
      {
        path: "select-products",
        name: "select-products",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("baf_app/SelectProducts"),
            loadingComponent: LoadingSpinner,
          }),
      },
      {
        path: "t3",
        name: "t3-form",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("baf_app/T3Form"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          permissionGuards: {
            permissions: [
              UserPermissions.READ_PRODUCTS,
              UserPermissions.WRITE_PRODUCT_APPROVALS,
            ],
            guardAction: GuardAction.AND,
          },
        },
      },
    ],
    meta: {
      requiresCustomerId: true,
    },
  },
  {
    path: "/customization",
    name: "customization",
    component: async () =>
      defineAsyncComponent({
        loader: () => import("customization_app/BasePage"),
        loadingComponent: LoadingSpinner,
      }),
    children: [
      {
        path: "user-redemption",
        name: "user-redemption-home",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("customization_app/RedemptionHome"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          breadCrumbTitle: "User redemption",
          allowInPlaygroundMode: true,
        },
      },
      {
        path: "user-redemption/template/create",
        name: "user-redemption-create",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("customization_app/RedemptionTemplateCreate"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          hideSidebar: true,
          useHeader: true,
          closeUrl: "user-redemption-home",
          helpUrl: REDEMPTION_TEMPLATE_HELP_URL,
          allowInPlaygroundMode: true,
        },
      },
      {
        path: "user-redemption/template/:id/edit",
        name: "user-redemption-edit",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("customization_app/RedemptionTemplateCreate"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          hideSidebar: true,
          useHeader: true,
          closeUrl: "user-redemption-home",
          helpUrl: REDEMPTION_TEMPLATE_HELP_URL,
          allowInPlaygroundMode: true,
        },
      },
      {
        path: "user-redemption/template/:id",
        name: "user-redemption-template-details",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("customization_app/RedemptionTemplateDetails"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          breadCrumbTitle: " ",
          allowInPlaygroundMode: true,
        },
      },
      {
        path: "payout-selection",
        name: "payout-selection-home",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("customization_app/PayoutHome"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          breadCrumbTitle: "Merchant selection",
          allowInPlaygroundMode: true,
        },
      },
      {
        path: "payout-selection/template/:id/edit",
        name: "payout-selection-edit",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("customization_app/PayoutTemplateCreate"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          hideSidebar: true,
          useHeader: true,
          closeUrl: "payout-selection-home",
          helpUrl: PAYOUT_TEMPLATE_HELP_URL,
          allowInPlaygroundMode: true,
        },
      },
      {
        path: "payout-selection/template/create",
        name: "payout-selection-create",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("customization_app/PayoutTemplateCreate"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          hideSidebar: true,
          useHeader: true,
          closeUrl: "payout-selection-home",
          helpUrl: PAYOUT_TEMPLATE_HELP_URL,
          allowInPlaygroundMode: true,
        },
      },
      {
        path: "payout-selection/template/:id",
        name: "payout-selection-template-details",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("customization_app/PayoutTemplateDetails"),
            loadingComponent: LoadingSpinner,
          }),
        meta: {
          breadCrumbTitle: " ",
          allowInPlaygroundMode: true,
        },
      },
    ],
    meta: {
      requiresCustomerId: true,
    },
  },
  {
    path: "/user/settings",
    name: "user-settings",
    component: UserSettings,
  },
  // Keep the not found route at the bottom
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: { preAuth: true },
  },
];
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (from.meta.scrollTopOnLeave || to.meta.scrollTopOnEnter)
      return {
        top: 0,
        left: 0,
      };
    else if (savedPosition) return savedPosition;
  },
  routes,
});

/**
 * Check if current route can be navigated by an un authenticated user
 */
export function canEnterCurrentRouteWithoutAuth(): boolean {
  // resolve based on location.pathname rather than router.currentRoute which is unresolved at initial page load
  const currentRoute = router.resolve(window.location.pathname);
  return !!currentRoute.meta.preAuth;
}

/**
 * Vue router global error handler
 */
router.onError((error) => {
  if (loadingLazyChunkFailed(error)) {
    alert("A refresh is required to update to latest version.");
    window.location.reload();
  }
});

/**
 * Check if dynamical module fetching failed using vue router error object.
 * Module is fetched dynamically by vue router using ES6 import statement e.g: import("./test/a.js")
 * It results in TypeError if module not found and is passed on to router's onError hook.
 * e.g. "TypeError: Failed to fetch dynamically imported module: http://localhost:3000/test/a.js"
 * @param error Error received by router error handler
 */
function loadingLazyChunkFailed(error: Error): boolean {
  if (error instanceof TypeError) {
    const loadChunkFailedMessageRegex =
      /failed to fetch dynamically imported module: .*\..*\.js/i;
    return loadChunkFailedMessageRegex.test(error.message);
  }
  return false;
}

export function isCurrentRouteAnOnboardingRoute() {
  // resolve based on location.pathname rather than router.currentRoute which is unresolved at initial page load
  const currentRoute = router.resolve(window.location.pathname);
  return currentRoute.matched.some((r) => r.name === "onboard");
}

export async function getRouteBasedOnAuthenticatedUserState(
  state: UserOnboardingState
): Promise<RouteLocationRaw> {
  if (await userLoggedInWithPublicEmail()) {
    return { name: "onboarding-personal-email-restriction" };
  }
  if (import.meta.env.VITE_ONBOARDING_STATE === "closed") {
    return { name: "onboarding-closed" };
  }
  if (state === UserOnboardingState.UserInviteExpired) {
    return { name: "onboarding-invite-expired" };
  }
  if (state === UserOnboardingState.UserCreationRequired) {
    return { name: "onboarding-account-details" };
  }
  if (state === UserOnboardingState.BelongsToActiveCustomer) {
    return { name: "home" };
  }
  if (state === UserOnboardingState.CustomerOnboardingTaskNotComplete) {
    return { name: "onboarding-taking-long" };
  }
  if (state === UserOnboardingState.CustomerApprovalRequired) {
    return { name: "onboarding-pending-confirmation" };
  }
  if (state === UserOnboardingState.CustomerWithDomainAlreadyExists) {
    return { name: "onboarding-customer-exists" };
  }
  if (state === UserOnboardingState.CustomerCreationRequired) {
    return { name: "onboarding-company-details" };
  }
  return { name: "auth-login" };
}

router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    // route guards
    let fallbackRoute: RouteLocationRaw | undefined;

    const existingTag = document.querySelector('meta[name="robots"]');
    if (existingTag) {
      existingTag.remove();
    }

    if (to.meta.noIndex) {
      const metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "robots");
      metaTag.setAttribute("content", "noindex");
      document.head.appendChild(metaTag);
    }

    try {
      fallbackRoute =
        customerIdRestrictionGuard(to, from) ||
        (await toggleFeatureRestrictionGuard(to, from)) ||
        (await useBalancesRestrictionGuard(to, from)) ||
        (await useFXRestrictionGuard(to, from)) ||
        (await balanceTncAcceptedRestrictionGuard(to, from)) ||
        (await auth0PermissionsRestrictionGuard(to, from)) ||
        usePlaygroundRestrictionGuard(to, from);
      if (fallbackRoute) return fallbackRoute;
      // more global guards can be added below like this:
      // fallbackRoute = xyzGuard(to, from);
      // if (fallbackRoute) return fallbackRoute;

      // pass query params to route
      if (to.meta.keepParams) {
        const hasQueryStringsFrom = !!Object.keys(from.query).length;
        const hasQueryStringsTo = !!Object.keys(to.query).length;

        if (!hasQueryStringsTo && hasQueryStringsFrom) {
          return { name: to.name!, query: from.query };
        } else {
          return true;
        }
      } else {
        return true;
      }
    } catch (error) {
      if (
        error instanceof Auth0NoPermissionsNavigationError ||
        error instanceof FXRestrictionError ||
        error instanceof PlaygroundModeError
      ) {
        return { name: "home" };
      }
      captureException(error);
      throw error;
    }
  }
);

export default router;
