<script setup lang="ts">
import Modal from "@two-ui/components/common/Modal.vue";
import TwoButton from "@two-components/components/TwoButton.vue";
import { showPlaygroundModal } from "../route-guards/use-playground-restriction";
import { COMPLETE_VERIFICATION_URL } from "@two-ui/constants";

const closeModal = () => {
  showPlaygroundModal.value = false;
};

const handleCompleteVerification = () => {
  window.open(COMPLETE_VERIFICATION_URL, "_blank");
  closeModal();
};
</script>

<template>
  <Modal
    title="Complete account setup"
    :show="showPlaygroundModal"
    @close="closeModal"
    containerClass="max-w-md"
  >
    <template #body>
      <p class="text-grey-600">
        This feature is not available until you complete your account setup. To
        complete setup, we need a few additional details to verify your
        business. After completing, you should be fully verified in 2-4 business
        days.
      </p>
    </template>
    <template #footer>
      <TwoButton @click="closeModal">Close</TwoButton>
      <TwoButton class="btn-primary" @click="handleCompleteVerification">
        Complete verification
      </TwoButton>
    </template>
  </Modal>
</template>
