<script lang="ts" setup>
import { onMounted, ref, computed } from "vue";
import {
  CustomerService,
  CustomerListItem,
  CustomerOrderLimit,
} from "@workspace/open-api/cms";
import store from "@two-ui/store";
import { SelectOption, TwoSelectField } from "@wegift/two-components";

const emit = defineEmits(["change"]);
const fetchedCustomers = ref<CustomerListItem[]>([]);
const customerSelectOptions = ref<SelectOption[]>([]);
const storedCustomer = computed(() =>
  fetchedCustomers.value.find(
    (customer) => customer.id == store.state.customerId
  )
);

const getCustomers = async () => {
  const customers = await CustomerService.getCustomerList();
  customers.sort((a, b) => a.name.localeCompare(b.name));
  fetchedCustomers.value = customers;
};

const setCustomerSelectOptions = () => {
  customerSelectOptions.value = fetchedCustomers.value.map((customer) => ({
    id: customer.id,
    value: customer.id,
    label: customer.name,
  }));
};

/**
 * Updates customer in store from fetched customers and emits change event
 * @param customerId id of fetched customer from api.
 */
const setCustomer = (customerId: string) => {
  const customer = fetchedCustomers.value.find(
    (customer) => customer.id === customerId
  );
  if (customer) {
    store.setCustomer(
      customer.id,
      customer.name,
      customer.contactEmailAddress,
      customer.features!,
      customer.source,
      customer.limitedAccess
    );
    if (customer.defaultCurrency) {
      store.setCustomerDefaultCurrency(customer.defaultCurrency);
    }
    if (customer.discountConfig) {
      store.setCustomerDiscountConfig(customer.discountConfig);
    } else {
      store.setCustomerDiscountConfig(null);
    }
    store.setOrderLimits(customer.orderLimits || null);
  }
  emit("change");
};

const setCurrentCustomerIfSingleCustomer = () => {
  if (fetchedCustomers.value.length === 1) {
    setCustomer(fetchedCustomers.value[0].id);
  }
};

onMounted(async () => {
  await getCustomers();
  setCustomerSelectOptions();
  if (storedCustomer.value) {
    // refresh stored customer values with fresh values fetched from api
    setCustomer(storedCustomer.value.id);
  }
  if (!storedCustomer.value) {
    setCurrentCustomerIfSingleCustomer();
  }
});
</script>

<template>
  <div>
    <TwoSelectField
      name="customer-select"
      v-if="fetchedCustomers.length > 1"
      :modelValue="storedCustomer?.id || ''"
      placeholder="Select a customer"
      :options="customerSelectOptions"
      class="w-48"
      @change="setCustomer"
      theme="dark"
    />
    <p v-else-if="fetchedCustomers.length === 1">
      {{ storedCustomer?.name || "" }}
    </p>
    <small v-if="fetchedCustomers.length > 0 && !storedCustomer"
      >Please select customer</small
    >
  </div>
</template>
